@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');
// @import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-zoom.css');
@import '~lightgallery/scss/lightgallery';
@import '~video.js/dist/video-js.css';

html, body {
  height: 100%;
}

body {
  margin: 0;
}

div, span {
  font-family: 'IBM Plex Sans', sans-serif !important;
}

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.video-js {
  position: unset !important;

  * {
    font-family: VideoJS !important;
  }

  .vjs-big-play-button {
    top: calc(50% - 0.75em);
    left: calc(50% - 1.5em);
  }


}

.lg-outer.lg-css3 {
  .lg-current {
    &.lg-complete {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }
}

.lg-outer .lg-item:before,
.lg-outer .lg-img-wrap:before {
  display: contents !important;
}

.lg-video-cont {
  width: 100% !important;
  height: 100% !important;
}

lightgallery {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 3px;

  .zoom {
    cursor: zoom-in;
  }

  .thumbnails {
    max-width: 100%;
    border-radius: 5px;
  }

  .gallery-img {

    &.video-thumb-container {
      position: relative;
      display: inline-flex;

      .play-button {
        top: calc(50% - 0.75em);
        left: calc(50% - 1.5em);

        font-size: 24px;
        line-height: 1.5em;
        height: 1.63332em;
        width: 3em;
        display: block;
        position: absolute;
        padding: 0;
        cursor: pointer;
        opacity: 1;
        border: 0.06666em solid #fff;
        color: #fff;
        background-color: #2B333F;
        background-color: rgba(43, 51, 63, 0.7);
        border-radius: 0.3em;
        transition: all 0.4s;

        .play-button-icon-placeholder {
          &:before {
            font-family: VideoJS;
            font-weight: normal;
            font-style: normal;
            content: "\f101";
          }
        }
      }

      &:hover {
        .play-button {
          background-color: rgba(115, 133, 159, 0.5);
        }
      }
    }

    &:first-child {
      max-width: 100%;
    }

    &:not(:first-child) {
      max-width: calc(33.33% - 2px);

      .play-button {
        font-size: 16px;
      }

      &:nth-child(3n+1) {
        // border-right: 3px red solid;
        // margin-right: 0;
      }
    }
  }
}

.sidenav {
  .mat-expansion-panel-body {
    padding: 0;
  }

  mat-expansion-panel-header {
    .mat-expansion-indicator::after {
      color: white;
    }

    &:hover {

      .mat-expansion-indicator::after {
        color: var(--primary-color);
      }

    }
  }
}

// .search {
mat-form-field {
  width: 100%;

  &.dropdown {
    // position: relative;
    // bottom: -6px;
  }


  .mat-form-field-flex {
    background-color: transparent !important;

    mat-datepicker-toggle button {
      color: var(--primary-color);
    }

    mat-label, .mat-form-field-label > span, .mat-datepicker-input {
      color: var(--primary-color);
      font-weight: 600;
    }

  }
}

#filters {

  mat-form-field {

    .mat-form-field-infix {
      min-width: 50px;
      max-width: 180px;
      width: unset;
    }
  }
  .mat-form-field-infix {
    // width: 100%;
  }
}

.report-status {
  mat-form-field {
    .mat-form-field-flex {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.search-toolbar {
  .mat-select-value {
    color: var(--primary-color);
    font-weight: 600;
  }
}

// }

.mat-option {
  &:not(.mat-option-disabled) {
    .mat-option-text {
      color: var(--primary-color);
    }
  }
}

.link {
  color: var(--primary-color) !important;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.check {
    color: var(--green-color) !important;
  }

  &.close {
    color: var(--error-color) !important;
  }

  &.mat-button-disabled {
    opacity: 0.2;
  }

  &.main {
    color: var(--green-color) !important;
  }
}

.light-link {
  color: var(--primary-color) !important;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

mat-card.without-underline {
  font-size: 16px;
  margin-bottom: 20px;
  padding: 5px 12px;

  &.no-padding {
    padding: 0;
  }

  mat-form-field {


    .mat-form-field-wrapper,
    .mat-form-field-flex {
      padding: 0;
    }

    .mat-form-field-infix {
      padding: 0.75em 0 0.75em 0;
      border: 0;

      .mat-select-arrow-wrapper {
        margin-top: 18px;
      }
    }

    .mat-form-field-underline {
      display: none !important;
    }
  }
}

.toast-top-center {
  top: 10px !important;
}

google-map {
  .map-container {
    width: 100% !important;
    // height: 240px !important;
    border-radius: 5px;
    min-width: 500px;
  }
}

.mat-radio-outer-circle, .mat-checkbox-frame {
  border-color: #1565c0 !important;
}
.mat-radio-checked {
  .mat-radio-inner-circle {
    background-color: #1565c0 !important;
  }
}
.mat-pseudo-checkbox {
  color: #1565c0 !important;
}
.mat-accent .mat-pseudo-checkbox-checked,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #1565c0 !important;
}

.in-row {
  // margin-bottom: 30px;
  mat-radio-button {
    margin-right: 20px;
  }
}

.page-header {
  height: 100px;
  padding: 0 48px;
  background-color: var(--page-header-background-color);
  display: flex;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 101;

  h1 {
    font-size: 34px !important;
    margin-right: 12px !important;
    margin-bottom: 0 !important;
    flex: 1;
    white-space: nowrap;
  }

  button.toolbar {
    min-width: 185px;
    height: fit-content;
    margin-left: 12px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--primary-color);

    &.mat-primary {
      color: var(--white-color);
    }
  }
}

.page-content {
  padding: 12px 48px;

  .data-table {
    table {
      box-shadow: none;
      width: 100%;
    }
  }

  a.map {
    margin: 20px 0 8px 0;
  }

  .mat-tab-header {
    position: sticky;
    top: 170px !important;
    z-index: 3;
    background-color: #fff;
  }
}

.no-padding {
  padding: 0 !important;
}

#filters {
  height: 70px;
  background-color: #ffffff;
  position: sticky;
  top: 100px;
  z-index: 5;

  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: end;

  button.search {
    position: relative;
    bottom: 16px;
  }
}

table.data {
  table-layout: fixed;

  .mat-table-sticky {
    top: 100px !important;
  }

  &.filter-header {
    .mat-table-sticky {
      top: 160px !important;
    }
  }

  td, th {
    padding: 10px;
  }

  td {
    &.truncated-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .category-type {
      .mat-badge-content {
        top: 0;
        right: -28px;
        text-overflow: unset;
        text-transform: lowercase;
      }
    }
  }

  .mat-row:hover {
    background-color: var(--light-grey-background-color);
  }

  .mat-row.removed {
    // background-color: #ffebee;

    .flag {
      mat-icon {
        position: relative;
        top: 5px;
        left: 10px;
      }
    }

    * {
      // pointer-events: none;
      // opacity: 0.5;
    }

    button {
      // display: none;
    }
  }
}

.data-table.tabs {
  table.data.filter-header {
    .mat-table-sticky {
      top: 0 !important;
    }
  }
}

.flag {
  mat-icon {
    color: #f44336;
  }
}

.bold-danger {
  font-weight: bold;
  color: #f44336;
}

.no-line {
  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-wrapper {
    padding: 0 !important;
  }
}

  $sizes: 50, 100, 120, 150, 200, 250;
  @each $size in $sizes {
    .column-#{$size} { width:#{$size}px; }
  }

.vertical-form {

  .mat-form-field-appearance-fill .mat-form-field-flex,
  .mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
    padding: 0px !important;
  }

  .mat-form-field-flex > div {
    border: none;
  }

  button.action {
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: bold;
    width: fit-content;
    margin-top: 16px;
  }

  .is-visible {
    margin-bottom: 15px;
  }

}

app-breadcrumbs {
  padding-bottom: 20px;
  display: block;
}

.search-select {
  line-height: 48px !important;
  padding: 0 16px !important;
  box-sizing: border-box !important;
}

.crud-page {

  .page-header {
    justify-content: space-between;

    h1 {
      flex: 1;
      font-size: 24px;
      margin-right: 12px;
      margin-bottom: 0;
      position: relative;
      top: 16px;
    }

    .back-button {
      position: absolute;
      margin-top: -36px;
      left: 24px;
      z-index: 5;
    }

    button {
      &.edit {
        position: relative;
        top: 12px;
      }

      &.toolbar {
        color: var(--white-color);
        min-width: fit-content;
      }
    }

    &.replaced-with-2-lines {
      h1 {
        top: 10px;
      }

      h2 {
        position: absolute;
        bottom: -20px;
        color: var(--dark-grey-color);
        // margin-left: 2px;
        font-size: 14px;
        width: 100%;
      }
    }
  }

  button.change-icon {
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;
    color: var(--white-color);
  }

  .page-content {
    padding: 30px 48px;
    // display: flex;
    font-family: 'IBM Plex Sans', sans-serif;

    &.top-breadcrumbs {
      padding-top: 20px;
    }

    form {
      width: 100%;
      max-width: 360px;

      &.wide {
        max-width: unset;
        display: flex;
        flex-direction: column;

        .categories-list {

          .mat-form-field-wrapper {
            padding-bottom: 0;
          }

          .mat-form-field-underline {
            bottom: 0;
          }
        }

        .mat-form-field {
          max-width: 360px;
        }
      }
    }

    .view-container {
      // margin-left: 16px;
      width: 100%;
      max-width: 360px;

      &.wide {
        max-width: unset;
        display: flex;

        .items {
          flex: 1;
        }

        .page-preview {
          // max-width: 300px;
          width: fit-content;

          .header {
            font-weight: bold;
            color: var(--primary-color);
          }

          .page-preview-container {
            margin-top: 10px;
            height: 100%;
            background-color: var(--light-grey-background-color);

            img {
              padding-right: 10px;
            }

            .mat-list-item {
              font-size: 14px;

              &.category-only {
                color: var(--error-color);
              }
            }

            .title {
              color: var(--dark-grey-color);
            }

          }
        }
      }
    }

    .categories-list {

      button.goto-page {
        letter-spacing: 2px;
        text-transform: uppercase;
        font-weight: bold;
        color: var(--primary-color);
        background-color: var(--light-blue-color);
        width: 130px;
        margin-left: 10px;
      }

      button.add-new {
        letter-spacing: 2px;
        text-transform: uppercase;
        font-weight: bold;
        width: 130px;
        margin-left: 10px;
      }

      .page-title {
        // width: 310px;
        margin-left: 10px;
        white-space: nowrap;
        display: flex;
        align-items: center;
      }

      .fillAbsentButtonSpace {
        margin-left: 150px !important;
      }

      .mat-checkbox {
        margin-left: 10px;
      }

      .category-name, .page-name, .sea-name {
        width: 150px;
        margin-bottom: 0;

        color: var(--primary-color);
        line-height: 48px;
        border-bottom: 1px solid #999;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &.category-only {
          color: var(--error-color);
        }
      }

      .sea-name {
        width: 120px;
        margin-left: 10px;
      }

      .sea-dropdown {
        width: 120px;
        margin-bottom: 0;
        margin-left: 16px;
      }

      .category-name-dropdown, .page-name-dropdown {
        width: 150px;
        margin-bottom: 0;

        color: var(--primary-color);

        .mat-select-value, .mat-select-arrow {
          color: var(--primary-color);
        }

        &.category-only {
          color: var(--error-color);

          .mat-select-value, .mat-select-arrow {
            color: var(--error-color);
          }
        }
      }

      .mat-list-item-content {
        padding-left: 0;
        font-size: 14px;
      }

      img {
        padding-right: 10px;
      }
    }

    mat-form-field {
      margin-bottom: 15px;
    }

    mat-card {
      mat-form-field {
        margin: 0;
      }
    }

    .file-input {
      display: none;
    }

    .chips {
      margin-top: 30px;
    }

    .icon-preview {
      display: flex;
      justify-content: center;
      align-content: center;

      max-width: 360px;
      width: 100%;

      img {
        max-width: 100%;
        min-width: 14px; // 230px;

        &[src$=".svg"] {
          min-width: 230px;
        }

        // background-color: var(--page-header-background-color);
        border-radius: 5px;
        overflow: hidden;

        &.zoom {
          cursor: zoom-in;
        }
      }
    }

    .column-header {
      color: var(--primary-color);
      font-weight: bold;
      margin-bottom: 10px;
      display: block;
    }

    textarea {
      height: 250px;
    }

    .items {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      .unconnected-pages {
        margin-top: 16px;
        margin-bottom: 16px;
        color: var(--error-color);

        .mat-icon {
          position: relative;
          top: 7px;
        }
      }

      .header {
        font-weight: bold;
        color: var(--primary-color);
      }

      .value {
        &.in-row {
          .mat-radio-button {
            margin-left: 32px;
          }
        }
      }

      .id {
        color: var(--accent-color);
      }
    }
  }

}

mat-select.inline-select {
  width: fit-content;
  min-width: 100px;
  margin-left: 6px;
}

.readonly-wrapper {
  // cursor: not-allowed;
}

.readonly-block {
  pointer-events: none;
  user-select: none;
}

.NgxEditor {
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  border-radius: 0 !important;

  .NgxEditor__Content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.md-drppicker {
  width: max-content !important;

  th {
    color: #383838 !important;
  }

  td.active {
    background-color: var(--primary-color) !important;

    &.today {
      color: #ffffff !important;
    }
  }

  button {
    color: #666 !important;
  }

  button.active {
    background-color: var(--primary-color) !important;
    color: #fff !important;
  }


  .ranges {
    float: right !important;
  }
}

.tab-custom-overflow .mat-tab-body-wrapper {
  overflow: visible !important;
}
.tab-custom-overflow .mat-tab-body-active {
  overflow: visible !important;
}
.tab-custom-overflow .mat-tab-body-content {
  overflow: visible !important;
}

.mat-select-search-input {
  padding-left: 8px !important;
}

.cdk-overlay-container .mat-select-panel {
  max-width: unset;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #1565c0 !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(21 101 192 / 40%) !important;
}

.mat-tooltip.notes {
  font-size: 14px !important;
}
mat-checkbox.vertical-layout {
  label {
    display: flex;
    flex-direction: column;
    align-items: center;

    .mat-checkbox-inner-container {
      margin: 0;
    }
  }
}

@media screen and (max-width: 1400px) {
  .inspector-report {
    .report-status {
      // text-align: right;
    }
  }
}

@media screen and (max-width: 768px) {
  .mat-toolbar {
    padding-right: 20px !important;
    &.without-button {
      padding-left: 20px !important;
    }
  }
}

@media screen and (max-width: 900px) {
  .inspector-report {
    &.page-header {
      height: auto !important;
      padding: 20px !important;
      align-items: start !important;
      flex-direction: column;
      justify-content: center;

      h1, div {
        flex: unset !important;
      }

      h1 {
        padding-bottom: 10px;
      }

      button.toolbar {
        margin-left: 0;
      }
    }

    &.page-content {
      padding: 0 !important;
      .columns-block {
        flex-direction: column;
        padding: 12px 20px !important;

        > div {
          margin-left: 0 !important;
        }

        .google-container {
          width: 100%;
        }
      }

      .logs-block {
        padding: 0 20px 20px 20px !important;
      }
    }

    .report-status {
      text-align: start;

      span {
        white-space: nowrap;
      }
    }
  }
}

@media screen and (max-width: 425px) {

  .inspector-report {
    .report-status {
      font-size: 90%;
    }
  }

}
