@import '~@angular/material/theming';
@include mat-core();

$custom-theme-primary: mat-palette($mat-green, 800);
$custom-theme-accent: mat-palette($mat-green, A100);
$custom-theme-warn: mat-palette($mat-amber, 500);

$custom-theme: mat-light-theme($custom-theme-primary, $custom-theme-accent, $custom-theme-warn);

@include angular-material-theme($custom-theme);

$primary: map-get($custom-theme, primary);
$accent: map-get($custom-theme, accent);

:root {
  --primary-color: #{mat-color($custom-theme-primary)};
  --accent-color: #{mat-color($custom-theme-accent)};
  --warn-color: #{mat-color($custom-theme-warn)};
  --error-color: #{mat-color(mat-palette($mat-red, 500))};

  --menu-hover-background-color: #{mat-color(mat-palette($mat-light-blue, 50))};
  --page-header-background-color: #{mat-color(mat-palette($mat-grey, 50))};
  --light-blue-color: #{mat-color(mat-palette($mat-light-blue, 50))};

  --light-grey-background-color: #{mat-color(mat-palette($mat-grey, 50))};
  --dark-grey-color: #{mat-color(mat-palette($mat-grey, 500))};

  --green-color: #{mat-color(mat-palette($mat-green, 500))};

  --white-color: #ffffff;
}
